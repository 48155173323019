import qetaImg from "../assets/qeta.png";
import machinesImg from "../assets/machines.png";
import doorWindowImg from "../assets/door-window.png";
import okarImg from "../assets/okar.png";
import PolywinProduct from "./PolywinProduct";
import GradientPolywin from "./GradientPolywin";

export default function PolywinProducts() {
  return (
    <div className="polywin-products-component">
      <h3 className="title">
        منتجات <GradientPolywin />
      </h3>
      <section className="products-list">
        <PolywinProduct image={qetaImg} name="القطاعات" />
        <PolywinProduct image={machinesImg} name="الماكينات" />
        <PolywinProduct image={doorWindowImg} name="الباب والشباك" />
        <PolywinProduct image={okarImg} name="الاكسسوارات" />
      </section>
    </div>
  );
}
