import { HOST } from "lib/constants";
import classes from "./agent.module.scss";
import { useAgentContext } from ".";
import { MapPin, Phone } from "lucide-react";

export default function TopHeader() {
  const { agent } = useAgentContext();

  return (
    <div className={classes.topHeader}>
      <img
        className={classes.agentLogo}
        src={`${HOST}/${agent.agentLogo}`}
        alt="logo"
      />
      <div>
        <h3 className={classes.agentName}>{agent.nameAgent}</h3>
        <p className={classes.agentPhone}>
          <Phone />
          <span>{agent.agentPhone}</span>
        </p>
      </div>
      <p className={classes.agentAddress} title={agent.agentAddress}>
        <MapPin />
        <span>{agent.agentAddress}</span>
      </p>
    </div>
  );
}
