import "styles/final-product.scss";
import { Color, MainCat, Prod } from "lib/productsImages";
import GradientPolywin from "components/GradientPolywin";
import { ReactComponent as ProductsArrow } from "assets/svg/products-arrow.svg";
import { ReactComponent as Arrow } from "assets/svg/top-arrow.svg";
import { useEffect, useState } from "react";
import { HOST } from "lib/constants";

export default function FinalProductPage() {
  const mainCats = MainCat.all();
  const [prodId, setProdId] = useState<number>(1);
  const [colorId, setColorId] = useState<number>(1);
  const [image, setImage] = useState(<img src="" alt="" />);

  const [openCat, setOpenCat] = useState(1);

  useEffect(() => {
    setImage(
      <img
        src={`${HOST}/${Prod.find(prodId)?.getColorImg(colorId)}`}
        alt={`${prodId}`}
        key={prodId}
      />
    );
  }, [prodId, colorId]);

  return (
    <div className="final-product-page">
      <header>
        <h2>
          منتجات <GradientPolywin />
        </h2>
        <p>
          منتجنا النهائي من أبواب وشبابيك وبلكونات مبهرة ومتنوعه يمكنك مشاهده
          المنتجات والتعرف على الوانها المختلفه
        </p>
      </header>
      <main>
        <div className="main-cats">
          {mainCats.map((mc) => (
            <div
              className={"main-cat " + (openCat === mc.id ? "open " : "")}
              key={mc.id}
            >
              <p
                className="main-cat-title"
                onClick={() => setOpenCat((id) => (id === mc.id ? 0 : mc.id))}
              >
                {mc.name} <Arrow />
              </p>
              <div className="sub-cats">
                {mc.getSubCategories().map((sc) => (
                  <div className="sub-cat" key={sc.id}>
                    <p className="sub-cat-title">{sc.name}</p>
                    <div className="product-list">
                      {sc.getProducts().map((p) => (
                        <div
                          className={
                            "product " + (prodId === p.id ? "selected " : "")
                          }
                          onClick={() => setProdId(p.id)}
                          key={p.id}
                        >
                          <ProductsArrow />
                          <p className="product-name">{p.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="img">{image}</div>
        <div className="color-choose">
          <p className="title">حدد اللون</p>
          <div className="color-list">
            {Color.all().map((c) => (
              <div
                key={c.id}
                className="color"
                onClick={() => setColorId(c.id)}
              >
                <div
                  className="color-show"
                  style={{ background: c.color }}
                ></div>
                <p className="color-name">{c.name}</p>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}
