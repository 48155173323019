import aboAmarImg from "../assets/managers/abo-amar.webp";
import atyaImg from "../assets/managers/atya.webp";
import abdulraheemImg from "../assets/managers/abdulraheem.webp";
import abdulhameedImg from "../assets/managers/abdulhameed.webp";
import TeamMember from "./TeamMember";

export default function Team() {
  return (
    <div className="team-component">
      <h3>مجلس الإدارة</h3>
      <div className="team-members">
        <TeamMember
          name="أ/ عبدالرحيم ديكمن"
          jobTitle="عضو مجلس إدارة ومؤسس"
          img={abdulraheemImg}
        />
        <TeamMember
          name="أ/ عطية عبده"
          jobTitle="عضو مجلس إدارة ومؤسس"
          img={atyaImg}
        />
        <TeamMember
          name="أ/ محمد عبدالحميد"
          jobTitle="عضو مجلس إدارة ومؤسس"
          img={abdulhameedImg}
        />
        <TeamMember
          name="أ/ محمد عمار"
          jobTitle="عضو مجلس إدارة ومؤسس"
          img={aboAmarImg}
        />
      </div>
    </div>
  );
}
