import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AppLayout from "components/AppLayout";
import HomePage from "pages/HomePage";
import MaterialsPage from "pages/materials/MaterialsPage";
import AgentsPage from "pages/agents/AgentsPage";
import FinalProductPage from "pages/final-product/FinalProductPage";
import GalleryPage from "pages/gallery/GalleryPage";
import DataSheet from "pages/data-sheet/DataSheet";
import AgentPage from "pages/AgentPage";

const router = createBrowserRouter([
  {
    path: "",
    element: <AppLayout />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "materials",
        element: <MaterialsPage />,
        children: [
          {
            path: ":id",
            element: <MaterialsPage />,
          },
        ],
      },
      {
        path: "agents",
        element: <AgentsPage />,
      },
      {
        path: "final-product",
        element: <FinalProductPage />,
      },
      {
        path: "gallery",
        element: <GalleryPage />,
        children: [
          {
            path: ":id",
            element: <GalleryPage />,
          },
        ],
      },
      {
        path: "agent/:userId",
        element: <AgentPage />,
      },
      {
        path: "data-sheet",
        element: <DataSheet />,
      },
    ],
  },
]);

export default function MyRouter() {
  return <RouterProvider router={router} />;
}
