export default function PolywinProduct({
  image,
  name,
}: {
  image: any;
  name: string;
}) {
  return (
    <div className="polywin-product-component">
      <img src={image} alt={name} />
      <p className="name">{name}</p>
    </div>
  );
}
