import classes from "./heroSection.module.scss";

export default function HeroSection() {
  return (
    <section className={classes.hero}>
      <div className={classes.heroContent}>
        <p className="top-text">الوكيل الحصري في مصر لقطاعات</p>
        <img src="/assets/images/white-logo.png" alt="logo" />
        <p className="bottom-text">
          وتوريد القطاعات والمواد الخام لصناعة ال UPVC التركي
        </p>
        <div className={classes.downloadBtns}>
          <a
            href="https://play.google.com/store/apps/details?id=com.pvc.polywin&hl=en"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/google-play.png" alt="google" />
            <div>
              <p>تحميل على</p>
              <p>Google Play</p>
            </div>
          </a>
          <a
            href="https://apps.apple.com/il/app/%D8%A8%D9%88%D9%84%D9%8A-%D9%88%D9%8A%D9%86/id1603332132"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/apple-logo.png" alt="apple" />
            <div>
              <p>تحميل على</p>
              <p>App Store</p>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}
