export class SheetType {
  constructor(public id: number, public srcName: string, public name: string) {}

  static all(): SheetType[] {
    return [
      new SheetType(1, "PicAfterInstalling", "صورة المنتج بعد التركيب"),
      new SheetType(2, "3D", "رسم ثلاثي الأبعاد"),
      new SheetType(3, "Details", "البيانات"),
    ];
  }
}

export class SheetCategory {
  constructor(public id: number, public srcName: string, public name: string) {}

  static all(): SheetCategory[] {
    return [
      new SheetCategory(1, "MefsalBig", "مفصلي كبير"),
      new SheetCategory(2, "MefsalEco", "مفصلي اقتصادي"),
      new SheetCategory(3, "MonzalekBig", "منزلق كبير"),
      new SheetCategory(4, "MonzalekEco", "منزلق اقتصادي"),
      new SheetCategory(5, "Kta3Takmle", "قطاعات تكميلية"),
    ];
  }
  getProducts(): Sheet[] {
    return Sheet.all().filter((sheet) => sheet.catId === this.id);
  }
}

export class Sheet {
  constructor(public id: number, public catId: number, public name: string) {}
  static all(): Sheet[] {
    return [
      new Sheet(1, 1, "P603_A_10"),
      new Sheet(2, 1, "P603_A_20"),
      new Sheet(3, 1, "P603_A_25"),
      new Sheet(4, 1, "P603_A_30"),
      new Sheet(5, 1, "P603_A_70"),
      new Sheet(6, 1, "P603_A_80"),
      new Sheet(7, 1, "P603.E.50"),
      new Sheet(8, 1, "P603.E.70"),
      new Sheet(9, 1, "P603.E.75"),
      new Sheet(10, 1, "P604_E_30"),
      new Sheet(11, 1, "P604.E.30"),
      new Sheet(12, 1, "P604.E.40"),
      new Sheet(13, 1, "YP_C_20"),
      new Sheet(14, 1, "YP_C_30"),
      new Sheet(15, 1, "YP60_10"),
      new Sheet(16, 2, "P502_10"),
      new Sheet(17, 2, "P502_20"),
      new Sheet(18, 2, "P502_30"),
      new Sheet(19, 2, "P502_40"),
      new Sheet(20, 2, "P502"),
      new Sheet(21, 2, "YP_C_50"),
      new Sheet(22, 2, "YP_C_60"),
      new Sheet(23, 2, "YP60_10"),
      new Sheet(24, 3, "PS70.A.10"),
      new Sheet(25, 3, "PS70.A.15"),
      new Sheet(26, 3, "PS70.A.15."),
      new Sheet(27, 3, "PS70.A.30"),
      new Sheet(28, 3, "PS70.A.35"),
      new Sheet(29, 3, "PS70.A.50"),
      new Sheet(30, 3, "PS70.A.55"),
      new Sheet(31, 3, "PS70.A.95"),
      new Sheet(32, 3, "PS70.A70"),
      new Sheet(33, 3, "PSS.25"),
      new Sheet(34, 3, "YP.C.50"),
      new Sheet(35, 3, "YP.C.60"),
      new Sheet(36, 4, "PS60.E.15"),
      new Sheet(37, 4, "PS60.E.25"),
      new Sheet(38, 4, "PS60.E.30"),
      new Sheet(39, 4, "PS60.E.40"),
      new Sheet(40, 4, "PS60.E.50"),
      new Sheet(41, 4, "PSS.20"),
      new Sheet(42, 4, "P502.20"),
      new Sheet(43, 5, "PK.30"),
      new Sheet(44, 5, "PS70.A.85"),
      new Sheet(45, 5, "PS80.A.50"),
      new Sheet(46, 5, "YP.10"),
      new Sheet(47, 5, "YP.30"),
      new Sheet(48, 5, "YP.40"),
      new Sheet(49, 5, "YP.60.40"),
      new Sheet(50, 5, "YP60.20"),
      new Sheet(51, 5, "YP60.30"),
    ];
  }
  getCat(): SheetCategory | undefined {
    return SheetCategory.all().find((sc) => sc.id === this.catId);
  }
}

export default function getSheetImgSrc(sheetId: number, typeId: number) {
  const curSheet = Sheet.all().find((sheet) => sheet.id === sheetId);
  const curType = SheetType.all().find((type) => type.id === typeId);
  if (!curSheet || !curType) return `notFound.jpg`;
  const curCat = curSheet.getCat();
  const path = `${curCat?.srcName}/${curType?.srcName}/${curSheet.name}.webp`;
  return path;
  // var pic = new Image();
  // pic.src = require(`../assets/datasheet/${path}`);
  // if (pic.height != 0) return path;
  // return `notFound.jpg`; // this is the problem
}
