import "styles/materials.page.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useGetParentCategoriesWithProductsQuery } from "state/apiSlices/guestApiSlice";
import GradientPolywin from "components/GradientPolywin";
import { useEffect } from "react";
import ParentProductCategory from "./ParentProductCategory";
import Product from "types/Product.type";
import CategoriesNav from "components/CategoriesNav";

export default function MaterialsPage() {
  const { data, isSuccess } = useGetParentCategoriesWithProductsQuery();

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess && isNaN(parseInt(id as string))) {
      if (data.length) navigate(`/materials/${data[0].id}`);
      else navigate("/");
    }
  }, [isSuccess, data, id, navigate]);

  const selected = data?.find((d) => `${d.id}` === id);

  return (
    <div className="materials-page">
      <header>
        <h2>
          خامات وقطاعات <GradientPolywin />
        </h2>
        <p>وفرنا لك منتج عالي الجودة وقطاعات تصلح لجميع أعمالك</p>
      </header>
      {isSuccess ? (
        <main>
          <header>
            <CategoriesNav materialData={data} path="materials" />
          </header>
          <section className="parent-product-category">
            <ParentProductCategory
              products={
                selected?.listCategory.reduce<Product[]>(
                  (l, c) => (c.products ? [...l, ...c.products] : l),
                  []
                ) ?? []
              }
            />
          </section>
        </main>
      ) : (
        "Loading..."
      )}
    </div>
  );
}
