import { Outlet } from "react-router";
import { Link, NavLink } from "react-router-dom";
import "../styles/layout.component.scss";
import logo from "../assets/polywin-logo.png";
import mail from "../assets/icons/mail.png";
import phone from "../assets/icons/phone.png";
import CSymbol from "../assets/icons/CSymbol.png";
import { useState } from "react";
import { ReactComponent as Facebook } from "assets/svg/facebook.svg";
import { ReactComponent as Instagram } from "assets/svg/instagram.svg";
import { ReactComponent as Youtube } from "assets/svg/youtube.svg";

export default function AppLayout() {
  const [showNav, setShowNav] = useState(false);

  const importantLinks = [
    {
      name: "الداتاشيت",
      link: "/data-sheet",
    },
    {
      name: "تواصل معنا",
      link: "",
    },
    {
      name: "عملاؤنا",
      link: "",
    },
    {
      name: "الخامات",
      link: "/materials",
    },
    {
      name: "معرضنا",
      link: "/gallery",
    },
    {
      name: "عن بولي وين",
      link: "",
    },
    {
      name: "الرئيسية",
      link: "",
    },
    {
      name: "المنتج النهائي",
      link: "/final-product",
    },
    {
      name: "الكاتالوجات",
      link: "",
    },
    {
      name: "التخصيمات",
      link: "",
    },
  ];

  return (
    <div className="app-layout-component">
      <nav className={"main-nav " + (showNav ? "show" : "")}>
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
        <div className="burger" onClick={() => setShowNav((n) => !n)}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-container" onClick={() => setShowNav(false)}>
          <ul>
            <li>
              <NavLink to={""}>الرئيسية</NavLink>
            </li>
            <li>
              <NavLink to={"/agents"}>الوكلاء</NavLink>
            </li>
            <li>
              <NavLink to={"/materials"}>الخامات</NavLink>
            </li>
            <li>
              <NavLink to={"/final-product"}>المنتج النهائي</NavLink>
            </li>
            <li>
              <a href="/#contact">تواصل معنا</a>
            </li>
            <li>
              <a href="/#about">عن بولي وين</a>
            </li>
            <li>
              <NavLink to={"/gallery"}>معرض الصور</NavLink>
            </li>
            <li>
              <NavLink to={"/data-sheet"}>الداتاشيت</NavLink>
            </li>
          </ul>
          <a
            className="certificate-link"
            href="http://certificate.tregegypt.com/"
          >
            شهادات الورش
          </a>
          <Link
            className="login-link"
            to={"https://polywin-old.vercel.app/Login"}
          >
            تسجيل الدخول
          </Link>
        </div>
      </nav>
      <div className="pageContainer">
        <Outlet />
      </div>
      <footer className="footer">
        <div className="footer-content">
          <img
            className="white-logo"
            alt="white-logo"
            src="/assets/images/white-logo.png"
          />
          <div className="important-links">
            <span className="title">روابط هامة</span>
            <ul className="links">
              {importantLinks.map((impLink) => (
                <li>
                  <Link to={`${impLink.link}`}>{impLink.name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="contact-info">
            <span className="title">تواصل معنا</span>
            <div className="mail">
              <img className="mail-icon" src={mail} alt="mail" />
              <span className="mail-details">admin@polywinegypt.com</span>
            </div>
            <div className="phone">
              <img className="phone-icon" src={phone} alt="phone" />
              <span className="phone-details">۱٥۲۳۱</span>
            </div>
            <div className="socials">
              <span>
                <a
                  href="https://www.facebook.com/polywin.eg"
                  className="facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Facebook width={36} height={36} />
                </a>
              </span>
              <span>
                <a
                  href="https://www.instagram.com/polywin.eg/"
                  className="instagram"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram width={36} height={36} />
                </a>
              </span>
              <span>
                <a
                  href="https://www.youtube.com/@polywinegypt"
                  className="youtube"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Youtube width={36} height={36} />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="footer-line"></div>
        <div className="footer-copyright">
          <span>&nbsp;Copyright</span>
          <img className="C-symbol" src={CSymbol} alt="copyright" />
          <span>&nbsp;2024&nbsp;</span>
          <span> Polywin all rights reserved</span>
        </div>
      </footer>
    </div>
  );
}
