import GradientPolywin from "components/GradientPolywin";
import "styles/agents.page.scss";
import locationIcon from "assets/location icon.png";
import mailIcon from "assets/mail icon.png";
import phoneIcon from "assets/phone icon.png";
import { useGetAllAgentsQuery } from "state/apiSlices/agentsApiSlice";
import { HOST } from "lib/constants";

export default function AgentsPage() {
  const { data, isSuccess } = useGetAllAgentsQuery();

  return (
    <div className="agents-container">
      <header>
        <h2>
          وكلاء <GradientPolywin />
        </h2>
        <p>لدينا قاعدة كبيرة من الوكلاء في جميع محافظات الجمهورية</p>
      </header>
      <main className="cards-container">
        {isSuccess
          ? data?.map((agent) => (
              <section key={agent.id} className="card">
                <div className="card-bgcircle"></div>
                <div className="card-logo">
                  <img
                    className="card-logo"
                    src={`${HOST}/${agent.agentLogo}`}
                    alt="logo"
                  />
                </div>
                <h3>{agent.nameAgent}</h3>
                <div className="card-city icon-info">
                  <img
                    className="location-icon"
                    src={locationIcon}
                    alt="location"
                  />
                  <span> {agent.agentGovernorate} </span>
                </div>
                <span> {agent.agentAddress} </span>
                <div className="line"></div>
                <div className="agents-phone icon-info">
                  <img className="icon" src={phoneIcon} alt="phone" />
                  <p>{agent.agentPhone}</p>
                </div>
                <div className="agents-email icon-info">
                  <img className="icon" src={mailIcon} alt="mail" />
                  <p>{!agent.email ? "لا يتوفر بريد إلكتروني" : agent.email}</p>
                </div>
                <button>الموقع علي الخريطة</button>
              </section>
            ))
          : "Loading..."}
      </main>
    </div>
  );
}
