export default function TeamMember({
  img,
  name,
  jobTitle,
}: {
  img: any;
  name: string;
  jobTitle: string;
}) {
  return (
    <section className="team-member-component">
      <img src={img} alt={name} />
      <p className="name">{name}</p>
      <span className="job-title">{jobTitle}</span>
    </section>
  );
}
