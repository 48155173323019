import Agent from "types/Agent";
import GalleryCategory from "../../types/Gallery";
import ParentProductCategory from "../../types/ParentProductCategory.type";
import { apiSlice } from "../apiSlice";

export const basePath = "PolyWinLogIn";

export const guestApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getParentCategoriesWithProducts: builder.query<
      ParentProductCategory[],
      void
    >({
      query: () => `${basePath}/GetParentCategorywithProductForWebApp`,
      transformResponse: ({ payload }: { payload: ParentProductCategory[] }) =>
        payload,
    }),
    getAllCategoryGallery: builder.query<GalleryCategory[], void>({
      query: () => `${basePath}/GetAllCategoryGallery`,
      transformResponse: ({ payload }: { payload: GalleryCategory[] }) =>
        payload,
    }),

    getAgentByUserId: builder.query<Agent, string>({
      query: (userId) => `${basePath}/GetAgentById?id=${userId}`,
      transformResponse: (data: { result: { payload: Agent } }) => {
        return data.result.payload;
      },
    }),
  }),
});

export const {
  useGetParentCategoriesWithProductsQuery,
  useGetAllCategoryGalleryQuery,
  useGetAgentByUserIdQuery,
} = guestApiSlice;
