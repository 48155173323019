import { useEffect, useState } from "react";
import "styles/data-sheet.page.scss";
import getSheetImgSrc, { SheetCategory, SheetType } from "lib/dataSheetInfor";
import GradientPolywin from "components/GradientPolywin";
import { ReactComponent as ProductsArrow } from "assets/svg/products-arrow.svg";
import { ReactComponent as Arrow } from "assets/svg/top-arrow.svg";
import { HOST } from "lib/constants";

export default function DataSheet() {
  const mainCats = SheetCategory.all();
  const mainTypes = SheetType.all();

  const [sheetId, setSheetId] = useState(1);
  const [typeId, setTypeId] = useState(3);
  const [image, setImage] = useState(<img src="" alt="" />);

  const [openCat, setOpenCat] = useState(1);

  function typeChangeHandler(
    e: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) {
    e.preventDefault();
    setTypeId(id);
  }

  useEffect(() => {
    setImage(
      <img
        src={`${HOST}/assets/datasheet/${getSheetImgSrc(sheetId, typeId)}`}
        alt={`${sheetId}`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = `${HOST}/assets/datasheet/notFound.jpg`;
        }}
        id={`${sheetId}`}
        key={sheetId}
        className="pic"
      />
    );
  }, [sheetId, typeId]);

  return (
    <div className="data-sheet-page">
      <header>
        <h2>
          بيانات منتجات <GradientPolywin />
        </h2>
        <p>
          منتجنا النهائي من أبواب وشبابيك وبلكونات مبهرة ومتنوعه يمكنك مشاهده
          المنتجات والتعرف على الوانها المختلفه
        </p>
      </header>
      <main>
        <div className="main-cats">
          {mainCats.map((mc) => (
            <div
              className={"main-cat " + (openCat === mc.id ? "open " : "")}
              key={mc.id}
            >
              <p
                className="main-cat-title"
                onClick={() => setOpenCat((id) => (id === mc.id ? 0 : mc.id))}
              >
                {mc.name} <Arrow />
              </p>
              <div className="sub-cats">
                <div className="sub-cat" key={mc.id}>
                  <div className="product-list">
                    {mc.getProducts().map((p) => (
                      <div
                        className={
                          "product " + (sheetId === p.id ? "selected " : "")
                        }
                        onClick={() => setSheetId(p.id)}
                        key={p.id}
                      >
                        <ProductsArrow />
                        <p className="product-name">{p.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="type-pic">
          <ul className="categories-links">
            {mainTypes.map((type) => (
              <li className="category">
                <button
                  className={type.id === typeId ? "active" : ""}
                  onClick={(e) => typeChangeHandler(e, type.id)}
                  id={type.id as unknown as string}
                >
                  {type.name}
                </button>
              </li>
            ))}
          </ul>
          <div className="pic" role="img">
            {image}
          </div>
        </div>
      </main>
    </div>
  );
}
