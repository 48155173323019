export default function GradientPolywin() {
  return (
    <span
      style={{
        background:
          "linear-gradient( 92deg, rgba(60, 193, 242, 0.9) -2.07%, #f26f21 97.79%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      }}
    >
      بولي وين
    </span>
  );
}
