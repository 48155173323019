import "styles/home.page.scss";
import PolywinProducts from "components/PolywinProducts";
import AboutPolywin from "components/AboutPolywin";
import ContactForm from "components/ContactForm";
import Team from "components/Team";
import OurWorks from "components/OurWorks";
import HeroSection from "components/HeroSection";

export default function HomePage() {
  return (
    <div className="home-page">
      <HeroSection />
      <PolywinProducts />
      <AboutPolywin />
      <Team />
      <OurWorks />
      <ContactForm />
    </div>
  );
}
