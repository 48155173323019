import { ReactComponent as TwoArrows } from "../assets/svg/two-arrows.svg";

export default function AboutPolywin() {
  return (
    <section className="about-polywin-component" id="about">
      <h3 className="title">عن بولي وين</h3>
      <main>
        <article className="about-content">
          <div className="about-content-p">
            <TwoArrows />
            <p>
              شركتنا هي واحدة من أكبر الشركات الرائدة في مجال استيراد خامات
              واكسسوار ومعدات وإنتاج وتطوير الأبواب والنوافذ والواجهات من قطاعات
              polywin
            </p>
          </div>
          <div className="about-content-p">
            <TwoArrows />
            <p>
              تتميز شركة polywin بتقديمها خامات و معدات تصنيع واكسسوار الأبواب
              والشبابيك والواجهات
            </p>
          </div>
        </article>
        <div className="diamond-shapes">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </main>
    </section>
  );
}
