export class Color {
  constructor(public id: number, public name: string, public color: string) {}

  static all(): Color[] {
    return [
      new Color(1, "أبيض", "#FFF"),
      new Color(2, "بيج", "#FFCE9C"),
      new Color(3, "خشبي", "#6B240C"),
    ];
  }
}

export class Prod {
  constructor(
    public id: number,
    public subCatId: number,
    public name: string
  ) {}

  static all(): Prod[] {
    return [
      new Prod(1, 1, "باب جرار 2 ضلفة"),
      new Prod(2, 1, "باب جرار 3 ضلفة"),
      new Prod(3, 1, "باب جرار 4 ضلفة"),
      new Prod(4, 2, "باب مفصلي 1 ضلفة"),
      new Prod(5, 2, "باب مفصلي 1 ضلفة على ضلفة"),
      new Prod(6, 2, "باب مفصلي 2 ضلفة"),
      new Prod(7, 2, "باب مفصلي 2 ضلفة على ضلفة"),
      new Prod(8, 3, "شباك جرار 2 ضلفة"),
      new Prod(9, 3, "شباك جرار 4 ضلفة"),
      new Prod(10, 4, "شباك مفصلي 1 ضلفة"),
      new Prod(11, 4, "شباك مفصلي 1 ضلفة على ضلفة"),
      new Prod(12, 4, "شباك مفصلي 2 ضلفة"),
      new Prod(13, 4, "شباك مفصلي 2 ضلفة على ضلفة"),
      new Prod(14, 4, "شباك قلاب"),
      new Prod(15, 4, "K&B شباك قلاب"),
      new Prod(16, 4, "شباك تقليدي"),
    ];
  }

  static find(id: number): Prod | undefined {
    return Prod.all().find((p) => p.id === id);
  }

  getColorImg(colorId: number): string | undefined {
    return Prod_Color.all().find(
      (prodColor) =>
        prodColor.prodId === this.id && prodColor.colorId === colorId
    )?.img;
  }

  getSubCategory(): SubCat | undefined {
    return SubCat.all().find((subCat) => subCat.id === this.subCatId);
  }
}

class Prod_Color {
  constructor(
    public id: number,
    public prodId: number,
    public colorId: number,
    public img: string
  ) {}

  static all(): Prod_Color[] {
    return [
      new Prod_Color(
        1,
        1,
        1,
        "assets/img/babgrar2Darfa/babgrar2darfawhite.jpg"
      ),
      new Prod_Color(
        2,
        1,
        2,
        "assets/img/babgrar2Darfa/babgrar2darfaBeige.jpeg"
      ),
      new Prod_Color(
        3,
        1,
        3,
        "assets/img/babgrar2Darfa/babgrar2darfaWood.jpeg"
      ),
      new Prod_Color(4, 2, 1, "assets/img/babgrar3Darfa/bab3darfaWhite.jpeg"),
      new Prod_Color(5, 2, 2, "assets/img/babgrar3Darfa/bab3darfaBeige.jpeg"),
      new Prod_Color(6, 2, 3, "assets/img/babgrar3Darfa/bab3darfaWood.jpeg"),
      new Prod_Color(7, 3, 1, "assets/img/babGrar4Darfa/bab4darfaWhite.jpeg"),
      new Prod_Color(8, 3, 2, "assets/img/babGrar4Darfa/bab4darfaBeige.jpeg"),
      new Prod_Color(9, 3, 3, "assets/img/babGrar4Darfa/bab4darfaWood.jpeg"),
      new Prod_Color(
        10,
        4,
        1,
        "assets/img/babmefsal1Darfa/mefsalDarfaWhite.jpg"
      ),
      new Prod_Color(
        11,
        4,
        2,
        "assets/img/babmefsal1Darfa/mefsalDarfaBeige.jpeg"
      ),
      new Prod_Color(
        12,
        4,
        3,
        "assets/img/babmefsal1Darfa/mefsalDarfaWood.jpeg"
      ),
      new Prod_Color(
        13,
        5,
        1,
        "assets/img/babmefsal1Darfa3laDarfa/mefsaldarfa3ladarfaWhite.jpg"
      ),
      new Prod_Color(
        14,
        5,
        2,
        "assets/img/babmefsal1Darfa3laDarfa/mefsaldarfa3ladarfaBeige.jpg"
      ),
      new Prod_Color(
        16,
        6,
        1,
        "assets/img/babmefsal2Darfa/mefsal2DarfaWhite.jpg"
      ),
      new Prod_Color(
        17,
        6,
        2,
        "assets/img/babmefsal2Darfa/mefsal2DarfaBeige.jpeg"
      ),
      new Prod_Color(
        18,
        6,
        3,
        "assets/img/babmefsal2Darfa/mefsal2DarfaWood.jpg"
      ),
      new Prod_Color(
        19,
        7,
        1,
        "assets/img/babmefsal2Darfa3laDarfa/mefsal2Darfa3laDarfaWhite.jpeg"
      ),
      new Prod_Color(
        20,
        7,
        2,
        "assets/img/babmefsal2Darfa3laDarfa/mefsal2Darfa3laDarfaBg.jpeg"
      ),
      new Prod_Color(
        22,
        8,
        1,
        "assets/img/windowGrar2Darfa/window2DarfaWhite.jpg"
      ),
      new Prod_Color(
        23,
        8,
        2,
        "assets/img/windowGrar2Darfa/window2DarfaBg.jpeg"
      ),
      new Prod_Color(
        24,
        8,
        3,
        "assets/img/windowGrar2Darfa/window2DarfaWood.jpeg"
      ),
      new Prod_Color(
        25,
        9,
        1,
        "assets/img/windowGrar4Darfa/window4DarfaWhite.jpeg"
      ),
      new Prod_Color(
        26,
        9,
        2,
        "assets/img/windowGrar4Darfa/window4DarfaBg.jpg"
      ),
      new Prod_Color(
        28,
        10,
        1,
        "assets/img/windowmefsal1Darfa/windowmefsa1DarfaWhite.jpg"
      ),
      new Prod_Color(
        29,
        10,
        2,
        "assets/img/windowmefsal1Darfa/windowmefsa1DarfaBg.jpeg"
      ),
      new Prod_Color(
        31,
        11,
        1,
        "assets/img/windowmefsal1Darfa/windowmefsa1DarfaWood.jpeg"
      ),
      new Prod_Color(
        32,
        11,
        2,
        "assets/img/windowmefsal1Darfa3Darfa/windowmefsal1Darfa3laDarfaWhite.jpg"
      ),
      new Prod_Color(
        33,
        11,
        3,
        "assets/img/windowmefsal1Darfa3Darfa/windowmefsal1Darfa3laDarfaBg.jpg"
      ),
      new Prod_Color(
        34,
        12,
        1,
        "assets/img/windowmefsal2Darfa/windowmefsal2DarfaWhite.jpeg"
      ),
      new Prod_Color(
        35,
        12,
        2,
        "assets/img/windowmefsal2Darfa/windowmefsal2DarfaBg.jpg"
      ),
      new Prod_Color(
        37,
        13,
        1,
        "assets/img/windowmefsal2Darfa/windowmefsal2DarfaWood.jpeg"
      ),
      new Prod_Color(
        38,
        13,
        2,
        "assets/img/windowmefsal2Darfa3Darfa/windowmefsal2Darfa3laDarfaWhite.jpg"
      ),
      new Prod_Color(
        39,
        13,
        3,
        "assets/img/windowmefsal2Darfa3Darfa/windowmefsal2Darfa3laDarfaBg.jpg"
      ),
      new Prod_Color(40, 14, 1, "assets/img/windowClub/windowClubWhite.jpg"),
      new Prod_Color(41, 14, 2, "assets/img/windowClub/windowClubBg.jpg"),
      new Prod_Color(42, 14, 3, "assets/img/windowClub/windowClubWood.jpg"),
      new Prod_Color(
        43,
        15,
        1,
        "assets/img/windowClubK-B/windowClubKBWhite.jpg"
      ),
      new Prod_Color(44, 15, 2, "assets/img/windowClubK-B/windowClubKBBg.jpg"),
      new Prod_Color(
        45,
        15,
        3,
        "assets/img/windowClubK-B/windowClubKBWood.jpg"
      ),
      new Prod_Color(
        46,
        16,
        1,
        "assets/img/windowTraditional/windowTraditinalWhite.jpg}"
      ),
      new Prod_Color(
        47,
        16,
        2,
        "assets/img/windowTraditional/windowTraditinalBg.jpg"
      ),
      new Prod_Color(
        48,
        16,
        3,
        "assets/img/windowTraditional/windowTraditinalWood.jpg"
      ),
      new Prod_Color(15, 5, 3, ""),
      new Prod_Color(21, 7, 3, ""),
      new Prod_Color(27, 9, 3, ""),
      new Prod_Color(30, 10, 3, ""),
      new Prod_Color(36, 12, 3, ""),
    ];
  }
}

export class SubCat {
  constructor(
    public id: number,
    public mainCatId: number,
    public name: string
  ) {}

  static all(): SubCat[] {
    return [
      new SubCat(1, 1, "أبواب جرار"),
      new SubCat(2, 1, "أبواب مفصلي"),
      new SubCat(3, 2, "شبابيك جرار"),
      new SubCat(4, 2, "شبابيك مفصلي"),
    ];
  }

  getMainCategory(): MainCat | undefined {
    return MainCat.all().find((mainCat) => mainCat.id === this.mainCatId);
  }

  getProducts(): Prod[] {
    return Prod.all().filter((prod) => prod.subCatId === this.id);
  }
}

export class MainCat {
  constructor(public id: number, public name: string) {}

  static all(): MainCat[] {
    return [new MainCat(1, "أبواب / بلكونات"), new MainCat(2, "شبابيك")];
  }

  getSubCategories(): SubCat[] {
    return SubCat.all().filter((subCat) => subCat.mainCatId === this.id);
  }
}

export {};
