export default function ContactForm() {
  return (
    <>
      <div className="contact-container" id="contact">
        <div className="contact-form">
          <div className="title">تواصل معنا</div>
          <div className="form-input">
            <div className="input-group">
              <section className="input-field">
                <label htmlFor="name">الاسم</label>
                <input type="text" name="name" />
              </section>
              <section className="input-field">
                <label htmlFor="phone">رقم الهاتف</label>
                <input type="text" name="phone" />
              </section>
            </div>
            <section className="input-field">
              <label htmlFor="email">البريد الإلكتروني</label>
              <input type="text" name="email" />
            </section>
            <section className="input-field">
              <label htmlFor="message">الرسالة</label>
              <textarea name="message" />
            </section>
          </div>
          <button className="form-cta">ارسال</button>
        </div>
        <div className="picture"></div>
      </div>
    </>
  );
}
