import { NavLink } from "react-router-dom";
import ParentProductCategory from "../types/ParentProductCategory.type";
import "../styles/categories.component.scss";
import GalleryCategory from "../types/Gallery";

export default function CategoriesNav({
  materialData,
  galleryData,
  path,
}: {
  materialData?: ParentProductCategory[];
  galleryData?: GalleryCategory[];
  path: string;
}) {
  return materialData ? (
    <ul className='categories-links'>
      {materialData.map((pc) => (
        <li className='category'>
          <NavLink to={`/${path}/${pc.id}`}>{pc.parentCategory}</NavLink>
        </li>
      ))}
    </ul>
  ) : galleryData ? (
    <ul className='categories-links'>
      {galleryData.map((pc) => (
        <li className='category'>
          <NavLink to={`/${path}/${pc.id}`}>{pc.name}</NavLink>
        </li>
      ))}
    </ul>
  ) : (
    <></>
  );
}
