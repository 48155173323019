import { useNavigate, useParams } from "react-router";
import { useGetAllCategoryGalleryQuery } from "state/apiSlices/guestApiSlice";
import { useEffect } from "react";
import CategoriesNav from "components/CategoriesNav";
import GradientPolywin from "components/GradientPolywin";
import { HOST } from "lib/constants";
import "styles/gallery.page.scss";

export default function GalleryPage() {
  const { data, isSuccess } = useGetAllCategoryGalleryQuery();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess && isNaN(parseInt(id as string))) {
      if (data.length) navigate(`/gallery/${data[0].id}`);
      else navigate("/");
    }
  }, [isSuccess, navigate, id, data]);
  if (isSuccess) console.log(data);

  const selected = data?.find((d) => `${d.id}` === id);
  console.log(selected);
  return (
    <div className="main-gallery-container">
      <header>
        <h2>
          معرض صور <GradientPolywin />
        </h2>
        <p>وفرنا لك منتج عالي الجودة وقطاعات مميزة من PVC تصلح لجميع أعمالك</p>
      </header>
      {isSuccess && selected ? (
        <main>
          <header>
            <CategoriesNav path="gallery" galleryData={data} />
          </header>
          <section className="gallery-cards">
            {selected.listGallery.length ? (
              selected.listGallery.map((category) =>
                category.gallery.map((crd) => (
                  <div key={crd.id} className="card">
                    <div className="card-pic">
                      <a href={`#${crd.id}`}>
                        <img
                          className="card-pic"
                          src={`${HOST}/${crd.pathImage}`}
                          alt="pic"
                        />
                      </a>
                      <button className="lightbox" id={`${crd.id}`}>
                        <span
                          style={{
                            backgroundImage: `url('${HOST}/${crd.pathImage}')`,
                          }}
                        ></span>
                      </button>
                    </div>
                    <div className="label">
                      <label>{crd.description}</label>
                    </div>
                  </div>
                ))
              )
            ) : (
              <></>
            )}
          </section>
        </main>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}
