import HeroSection from "components/HeroSection";
import classes from "./agent.module.scss";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { Navigate, useParams } from "react-router";
import { useGetAgentByUserIdQuery } from "state/apiSlices/guestApiSlice";
import { customCreateContext } from "lib/utils";
import Agent from "types/Agent";
import TopHeader from "./TopHeader";

export const [useAgentContext, AgentContextProvider] = customCreateContext<{
  agent: Agent;
}>();

export default function AgentPage() {
  const { userId } = useParams();

  const { data, isLoading } = useGetAgentByUserIdQuery(userId!, {
    skip: !userId,
  });

  if (!userId) return <Navigate to="/" />;

  return (
    <div>
      <HeroSection />
      {isLoading ? (
        <div className={classes.spinnerCont}>
          <LoadingSpinner />
        </div>
      ) : data ? (
        <AgentContextProvider value={{ agent: data }}>
          <div className={classes.mainContainer}>
            <TopHeader />
          </div>
        </AgentContextProvider>
      ) : null}
    </div>
  );
}
