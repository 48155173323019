import Agent from "../../types/Agent";
import { apiSlice } from "../apiSlice";

export const basePath = "api/Agent";

export const agentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAgents: builder.query<Agent[], void>({
      query: () => `${basePath}/getAllAgents`,
      transformResponse: ({ payload }: { payload: Agent[] }) => payload,
    }),
  }),
});

export const { useGetAllAgentsQuery } = agentsApiSlice;
