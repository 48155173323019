import big1 from "../assets/our-works/big1.webp";
import big2 from "../assets/our-works/big2.webp";
import small1 from "../assets/our-works/small1.webp";
import small2 from "../assets/our-works/small2.webp";
import small3 from "../assets/our-works/small3.webp";
import small4 from "../assets/our-works/small4.webp";
import small5 from "../assets/our-works/small5.webp";
import small6 from "../assets/our-works/small6.webp";
import small7 from "../assets/our-works/small7.webp";

export default function OurWorks() {
  const images = {
    big1,
    big2,
    small1,
    small2,
    small3,
    small4,
    small5,
    small6,
    small7,
  };
  return (
    <div className="our-works-component">
      <p className="title">من أعمالنا</p>
      <div className="image-grid">
        {Object.entries(images).map(([k, v]) => (
          <img className={k} key={k} src={v} alt={k} />
        ))}
      </div>
    </div>
  );
}
