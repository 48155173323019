import Product from "types/Product.type";
import codeIcon from "assets/code-icon.png";
import { HOST } from "lib/constants";

export default function ParentProductCategory({
  products,
}: {
  products: Product[];
}) {
  return (
    <div className="parent-product-category-component">
      {products.map((p) => (
        <div key={p.id} className="product">
          <img
            className="product-image"
            src={`${HOST}/${p.imgURL}`}
            alt={p.name}
          />
          <div className="separator"></div>
          <div className="code">
            <img src={codeIcon} alt="code" />
            <span>الكود: {p.productCode}</span>
          </div>
          <span className="product-name">{p.name}</span>
        </div>
      ))}
    </div>
  );
}
